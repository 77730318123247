import "./rootPage.scss";

import { Event } from "~models/event";
import { Extrait } from "~models/extrait";
import { Integrale } from "~models/integrale";
import { Program } from "~models/program";
import { Unit } from "~models/unit";
import { PlayerPage } from "~pages/player/playerPage";
import { ProgramTab } from "~pages/program/programTab";
import { createListComponent, DOMHelper, IListComponent, IPage, Keys, StaticModelSource, View } from "~ui-lib";

import { parseMarkerPianoPageDisplay } from "../datas/parser";
import { Plugin } from "../datas/plugin";
import { exitApp, navigationStack } from "../main";
import { Collection } from "../models/collection";
import { ParamClickZone, sendClickPianoEvent } from "../tools/analytics/piano";
import { MainMenuComponent, MainMenuItemSlug } from "../views/mainMenu/mainMenu";
import { HomeTab } from "./home/homeTab";
enum TopLevelID {
  menu = "menu",
  content = "content",
}

export const pushTabWithMenu = (content: View, htmlEltId: string, slugOn?: MainMenuItemSlug) => {
  navigationStack.pushPage(new RootPage(content, htmlEltId, slugOn));
};

export type PlayableItem = Integrale | Unit | Extrait;

export const pushPlayerPage = (model: PlayableItem, index?: number) => {
  const playerPage = new PlayerPage(model);
  navigationStack.pushPage(playerPage);
  playerPage.init(); // wait that player page is pushed on navigation before launch other states screen (loader/errors)
};

export type Tile = Event | Program | Unit | Collection | Integrale | Extrait;

export const onSelectTile = (
  model: Tile,
  index: number,
  zoneClick: ParamClickZone | string = ParamClickZone.playlist,
  searchString?: string,
  isPopup = false
) => {
  checkPianoProperties(model, zoneClick, isPopup, index, searchString);
  if (isPopup) {
    return true;
  }
  if (model instanceof Unit) {
    pushPlayerPage(model, index);
    return true;
  }

  if (model instanceof Integrale || model instanceof Extrait) {
    // if video is selected in MEA homepage swimlane and the video is associated with a program
    //   the app will open the program page and will put the focus on the video
    if (model.extras.program !== undefined && zoneClick === ParamClickZone.mea) {
      pushTabWithMenu(new ProgramTab(model.extras.program, model.id), "ProgramPage");
    } else {
      pushPlayerPage(model, index);
    }
    return true;
  }

  if (model instanceof Program) {
    pushTabWithMenu(new ProgramTab(model), "ProgramPage");
    return true;
  }

  if (model instanceof Collection) {
    pushTabWithMenu(new ProgramTab(model), "ProgramPage");
    return true;
  }
  console.log("Model type not handle yet : ");
  console.log(model);
  return true;
};

const checkPianoProperties = (
  item: Tile | PlayableItem,
  zone: ParamClickZone | string,
  isPopup: boolean,
  position?: number,
  searchString?: string
) => {
  const markerPiano = parseMarkerPianoPageDisplay(item.extras);

  if (item instanceof Event || markerPiano === undefined) {
    Plugin.getInstance()
      .fetchDetailled(item)
      .subscribe(value => {
        value[0]["itemCollection"] = {};
        value[0]["itemCollection"]["type"] = item.itemCollection?.type;
        value[0]["itemCollection"]["title"] = item.itemCollection?.title;
        sendClickPianoEvent(value[0], zone, isPopup, position, searchString);
      });
  } else {
    sendClickPianoEvent(item, zone, isPopup, position, searchString);
  }
};

class RootPage extends View implements IPage {
  private static _mainMenu: MainMenuComponent = MainMenuComponent.getInstance(); // static because we need the menu items being fetched only one time by session
  private _content: View;
  private _list?: IListComponent;
  private _slugOn?: MainMenuItemSlug;
  private _isALevelOnePage = false; // (level 1 pages are pages that user can opened from the menu (home, search, live, ...))

  constructor(content: View, htmlEltId: string, slugOn?: MainMenuItemSlug) {
    super(DOMHelper.createDivWithParent(null, htmlEltId, null));
    this._content = content;
    if (slugOn) {
      this._slugOn = slugOn;
      this._isALevelOnePage = true; // conditioned by param slugOn, might be better to add another parameter in the constructor
      navigationStack.destroyStack();
    }

    this.delegate = this._list = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource([TopLevelID.menu, TopLevelID.content]),
        viewFactory: model => {
          switch (model) {
            case TopLevelID.menu:
              return RootPage._mainMenu;
            case TopLevelID.content:
              return this._content;
            default:
              return this._content;
          }
        },
        horizontal: true,
        pageSize: 2,
        visibleBefore: 1,
        visibleAfter: 1,
        spatialFocus: false,
      },
      list => {
        //TODO: fix temporaire me close pas normalement sur réglage
        list.setFocusOnId(TopLevelID.menu);
        list.setFocusOnId(TopLevelID.content);
        if (slugOn) {
          RootPage._mainMenu.selectMenuItemSlug(slugOn);
        }
      }
    );
  }

  onNav = (key: Keys): boolean => {
    switch (key) {
      case Keys.back:
        // the exitPopup is displayed
        // - when user click "back" and the MENU IS FOCUSED (event if user is in a level 2, 3, 4, ... page)
        // - or if user is in the homePage (even if then menu is not opened)
        // else if user is on a level 1 page (level 1 pages are pages that user can opened from the menu (search, ageSelection, settings...))
        // - the app open the "homePage"
        //
        // in the other cases ( user on level 2, 3, 4, ... page and focus in content)
        // - return false (the navigationStack will handle the "back" and will remove the page)
        if (this._list?.focusedId$.value === TopLevelID.menu || this._content instanceof HomeTab) {
          exitApp();
        } else {
          if (this._isALevelOnePage) {
            pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
          }
          navigationStack.removePage(this);
        }
        return true;
    }
    return false;
  };
}
