import { Storage } from "~libs/storage";
import { AgePage } from "~pages/gestionAge/agePage";

import { DOMHelper, IPage, View } from "../../../bud-lite-tv/src/lib";
import { Plugin } from "../../datas/plugin";
import { navigationStack } from "../../main";
import { Didomi } from "../../tools/cmp/didomi";
import { deepLinkHandlerSingleton } from "../../tools/deeplink";
import { MainMenuItemSlug } from "../../views/mainMenu/itemMenu";
import { MainMenuComponent } from "../../views/mainMenu/mainMenu";
import { ConsentManagementTab } from "../cmp/consentManagement/consentManagementPage";
import { ItemAge } from "../gestionAge/itemAge";
import { HomeTab } from "../home/homeTab";
import { pushTabWithMenu } from "../rootPage";
import { SplashscreenView } from "./splashscreenView";

export class LaunchPage extends View implements IPage {
  private errorOnFetch = false;
  animationDuration = 2000;
  animationEnded = false;
  private splashscreenView: SplashscreenView;

  constructor() {
    super(DOMHelper.createDivWithParent(null, "LaunchPage"));

    this.splashscreenView = new SplashscreenView(() => {
      void this._onSplashscreenFinished();
    });
    this.rootElement.appendChild(this.splashscreenView.rootElement);

    if (!Storage.getItem("muted")) {
      Storage.setItem("muted", "false");
    }

    this.splashscreenView.start();
  }

  private _onSplashscreenFinished = async () => {
    this.animationEnded = true;

    // const age = Plugin.getInstance().user.getUserAge();
    // if (age === undefined) {
    //   this.age = (await this._fetchAge())?.age;
    // }

    /**
     * User with valid consent -> AgePage -> HomeTab
     * User without valid consent -> ConsentManagementTab -> AgePage -> Home Page
     *
     * Meaning that we should:
     * 1. Check Didomi
     * 2. Redirect to age page / home page
     */

    // 1. Initialize didomi for current device
    await Didomi.getUserConsent();
    // 2. Check if user should be prompted CMP
    if (Didomi.shouldUserBePromptedCmp()) {
      navigationStack.destroyStack();
      navigationStack.pushPage(new ConsentManagementTab());
    } else {
      await openFirstAppPage();
    }
  };
}

export const openFirstAppPage = async () => {
  // openFirstAppPage can be called from CmpPages
  // if HomeTab._currentAge is already set that means we come from settings
  navigationStack.destroyStack();
  if (HomeTab._currentAge !== undefined) {
    pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
    return;
  }
  const age = Plugin.getInstance().user.getUserAge();

  const handlePageDeepLinkAtStart = () => {
    deepLinkHandlerSingleton.checkDeeplinkData();
    const hasDeeplinkOccurred = deepLinkHandlerSingleton.handlePageDeeplink();
    if (hasDeeplinkOccurred === false) {
      pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
    }
  };

  if (age === undefined) {
    navigationStack.pushPage(
      new AgePage(true, () => {
        handlePageDeepLinkAtStart();
      })
    );
  } else {
    try {
      // TODO: Improve typing
      const ageItems: ItemAge[] = await Plugin.getInstance().fetchAge().toPromise();

      const ageItem = ageItems.find(ageItem => ageItem.age === age);
      if (ageItem !== undefined) {
        // TODO: Improve age selection ?
        MainMenuComponent.setMenuAge(ageItem);
        AgePage.selectAge = ageItem;
        HomeTab._currentAge = ageItem;
      }
      handlePageDeepLinkAtStart();
    } catch (e) {
      Log.app.error(e);
      // impossible to launch the app need a popup to retry
      navigationStack.pushPage(new AgePage(true, () => {}));
    }
  }
};
