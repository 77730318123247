import "./miseEnAvantSwimlane.scss";

import { ItemCollection } from "~models/itemCollection";
import { Program } from "~models/program";
import { onSelectTile } from "~pages/rootPage";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";

import { Extrait } from "../models/extrait";
import { Integrale } from "../models/integrale";
import { Unit } from "../models/unit";
import { ParamClickZone } from "../tools/analytics/piano";
import { MiseEnAvantView } from "../views/miseEnAvantView";

export class MiseEnAvantSwimlane extends View {
  constructor(source: ItemCollection) {
    super(DOMHelper.createDivWithParent(null, "", "meaSwimlane"));
    const logoContainer = DOMHelper.createDivWithParent(this.rootElement, "LogoContainer", "logoContainer");
    const logo = DOMHelper.createImg(logoContainer, null, "logo", require("~images/pictos_okoo/logo_okoo.png"), "");

    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as (Program | Unit | Integrale | Extrait)[]),
      viewFactory: model => {
        return new MiseEnAvantView(model);
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 2,
      visibleBefore: 1,
      visibleAfter: 1,
      onSelect: (model, index) => {
        return onSelectTile(model, index, ParamClickZone.mea);
      },
    });
  }
}
